import { useCallback } from "react";

import type { Fetcher, Key, SWRConfiguration, SWRResponse } from "swr";
import useSWR from "swr";
import type { SWRMutationConfiguration } from "swr/mutation";
import useSWRMutation from "swr/mutation";

import useBaseFetcher from "@/src/hooks/useBaseFetcher";

interface Props<Req> {
  url: string;
  payload: Req;
  /** Cache key for SWR. If given null, it won't fetch, can be used for conditional fetching. */
  cacheKey: Key;
  /** Options for SWR. For full list and definition, see https://swr.vercel.app/docs/options */
  swrOptions?: Partial<SWRConfiguration>;
}

/**
 * useSWRFetch
 * --------------
 * API fetching that utilizes SWR (stale while revalidate).
 * The data will be cached depends on the given cacheKey, stored in client to be reused.
 * Calling the hook with same cacheKey won't trigger an API call.
 *
 * To update the cached data, use `mutate()`.
 * This can also be utilized to do optimistic update (updating the UI before calling the API),
 *
 * Best used for fetching and showing data.
 * To call an API that submitting data, it's better to use `useAPI` hooks.
 *
 * More detail regarding how SWR works can be read at https://swr.vercel.app/
 *
 * @returns {Object} swrState - The state of the hooks.
 * @returns {Object} swrState.data - The data returned from the API call. Will be `null` if not yet fetched
 * @returns {Error} swrState.error - Error object. Will be `null` if there's no error.
 * @returns {boolean} swrState.isValidating - Indicating if there's ongoing request or revalidation.
 * @returns {function} swrState.mutate - Called to revalidate the cache.
 *
 */
function useSWRFetch<Res, Req = unknown, MiddlewareFn = unknown>(props: Props<Req>) {
  const { url, payload, cacheKey, swrOptions } = props;
  const baseFetcher = useBaseFetcher<Res, Req>();

  const fetcher: Fetcher<Res> = useCallback(async () => {
    const result = await baseFetcher(url, payload);
    return result;
  }, [url, payload, baseFetcher]);

  const appliedSwrOptions: SWRConfiguration = {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    // prevent unintended infinite retry that might flood BE traffic
    errorRetryCount: 2,
    ...swrOptions,
  };

  // return hook as is for enabling performance boost
  // https://swr.vercel.app/docs/advanced/performance#dependency-collection
  return useSWR<Res, Error>(cacheKey, fetcher, appliedSwrOptions) as SWRResponse<Res, unknown> & MiddlewareFn;
}

interface SWRMutationProps<Res, Req> {
  url: string;
  payload: Req;
  /** Cache key for SWR. If given null, it won't fetch, can be used for conditional fetching. */
  cacheKey: Key;
  /** Options for SWR. For full list and definition, see https://swr.vercel.app/docs/options */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  swrOptions?: SWRMutationConfiguration<Res, Error, any, any>;
}
export function useSWRMutationFetch<Res, Req = unknown>(props: SWRMutationProps<Res, Req>) {
  const { url, payload, cacheKey, swrOptions } = props;
  const baseFetcher = useBaseFetcher<Res, Req>();

  const fetcher: Fetcher<Res> = useCallback(async () => {
    const result = await baseFetcher(url, payload);
    return result;
  }, [url, payload, baseFetcher]);

  return useSWRMutation<Res, Error>(cacheKey, fetcher, swrOptions);
}
export default useSWRFetch;
