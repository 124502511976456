import * as Constant from "@/src/constants/AppConstant";
import useGetMyUserId from "@/src/domains/user/components/User/hooks/useGetMyUserId";
import type { UserProfileData } from "@/src/domains/user/components/UserProfile/types/UserProfile";
import useSWRFetch, { useSWRMutationFetch } from "@/src/hooks/useSWRFetch";

// Empty userId and spaceId means fetching my global profile with my profile space infos without space stats
// It will include global space stats
function useGetProfile(userId?: number, spaceId?: number) {
  // const shouldFetch = usePrioritizeContent();
  const myUserId = useGetMyUserId();

  const userPayload = userId || myUserId;
  return useSWRFetch<{ userData: UserProfileData }>({
    url: Constant.API_ENDPOINT_GET_USER_PROFILE_V2,
    cacheKey:
      userPayload === 0
        ? null
        : [
            Constant.API_ENDPOINT_GET_USER_PROFILE_V2,
            userId?.toString() || myUserId.toString(),
            spaceId?.toString() || "",
          ],
    payload: {
      // If no spaceId means it is my own user id can just send undefined BE will return the same or put our own userId, to simplified the cache key i use mySpaceId
      userId: userId || myUserId,
      spaceId,
    },
  });
}

export default useGetProfile;

// Empty userId and spaceId means fetching my global profile with my profile space infos without stats
// It will include global space stats
export function useGetProfileManualMutation(userId?: number, spaceId?: number) {
  const myUserId = useGetMyUserId();
  return useSWRMutationFetch<{ userData: UserProfileData }>({
    url: Constant.API_ENDPOINT_GET_USER_PROFILE_V2,
    cacheKey: [
      Constant.API_ENDPOINT_GET_USER_PROFILE_V2,
      userId?.toString() || myUserId.toString(),
      spaceId?.toString() || "",
    ],
    payload: {
      // If no spaceId means it is my own user id can just send undefined BE will return the same or put our own userId, to simplified the cache key i use mySpaceId
      userId: userId || myUserId,
      spaceId,
    },
    swrOptions: {
      populateCache: true,
    },
  });
}
