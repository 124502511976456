import { useCallback, useEffect } from "react";

import type { AxiosRequestConfig, Method } from "axios";
import type { Options } from "axios-hooks";
import useAxios, { configure } from "axios-hooks";
import HttpStatus from "http-status-codes";
import { useSelector } from "react-redux";

import * as Constant from "@/src/constants/AppConstant";
import axios from "@/src/core/AxiosFetcher";
import { generateHeaders, getDeviceContext, mapErrorFromData } from "@/src/hooks/helpers/apiHelpers";
import useLogout from "@/src/hooks/useLogout";
import { getDebugHeaderState, getDebugState } from "@/src/stores/debugConfig/debugConfigReducer";
import { selectors as userSelectors } from "@/src/stores/user";
import { getNextJsAPIBaseUrl, getSearchAPIBaseUrl } from "@/src/utils/APIUtil";

configure({ axios });

interface UseAxiosOptions extends Options {
  applyDeviceContext?: boolean;
  enableIdempotency?: boolean;
}

interface BaseResponse {
  status: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useApi = <T = any>(config: AxiosRequestConfig, options?: UseAxiosOptions) => {
  const { applyDeviceContext = true, enableIdempotency = false, ...baseOptions } = options || {};

  const accessToken = useSelector(userSelectors.accessTokenSelector);
  const debuggerState = useSelector(getDebugState());
  const additionalDebugHeader = getDebugHeaderState(debuggerState);
  const logout = useLogout();

  let additionalData = {};

  if (applyDeviceContext) {
    additionalData = {
      deviceContext: getDeviceContext(),
      frontendVersion: Constant.APP_VERSION,
    };
  }

  let headers = generateHeaders(accessToken, enableIdempotency, {
    ...config.headers,
  } as Record<string, string>);

  if (additionalDebugHeader) {
    headers = {
      ...headers,
      ...additionalDebugHeader,
    };
  }

  const payload = {
    method: "POST" as Method,
    ...config,
    headers: {
      ...config.headers,
      ...headers,
    },
    data: {
      ...config.data,
      ...additionalData,
    },
  };
  const [{ data, loading, error, response }, execute] = useAxios<BaseResponse & T>(payload, {
    manual: true,
    ...baseOptions,
  });

  let mappedResponse;
  let mappedData;
  let mappedError;

  if (response) {
    mappedResponse = response;

    // Some API didn't return status in it's response and we will still treat it as success
    // Do we need to handle the other response codes?
    if (
      response.status === HttpStatus.OK &&
      data &&
      (typeof data.status === "undefined" || data.status === HttpStatus.OK)
    ) {
      mappedData = data;
    } else {
      if (data) {
        mappedError = mapErrorFromData(data);
      }
    }
  } else if (error) {
    mappedResponse = error.response;
    mappedError = mapErrorFromData(error.response?.data) || error;
  } else {
    // No response yet, leave it
  }

  useEffect(() => {
    if (error) {
      // @TODO:
      // Display alert message with error or "something went wrong" if the error message it's not defined
    }
  }, [error, logout]);

  const executeWithAdditionalData = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (data: any) => {
      if (typeof data === "object" && data.data) {
        return execute({ ...data, data: { ...data.data, ...additionalData } });
      }
      return execute({ ...data, data: additionalData });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [execute],
  );
  const responseValue = { data: mappedData, loading, error: mappedError, response: mappedResponse };
  const result: [typeof responseValue, typeof execute] = [responseValue, executeWithAdditionalData];
  return result;
};

export default useApi;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useSearchAPI = <T = any>(config: AxiosRequestConfig, options?: UseAxiosOptions) => {
  const newConfig = {
    ...config,
    baseURL: getSearchAPIBaseUrl(),
  };
  return useApi<T>(newConfig, options);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useNextJsAPI = <T = any>(config: AxiosRequestConfig, options?: UseAxiosOptions) => {
  const newConfig = {
    ...config,
    baseURL: getNextJsAPIBaseUrl(),
  };
  return useApi<T>(newConfig, options);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const usePublicAPI = <T = any>(config: AxiosRequestConfig, options?: Options) => {
  return useAxios<BaseResponse & T>(
    {
      method: "POST" as Method,
      headers: generateHeaders(undefined, false, config.headers as Record<string, string>),
      ...config,
    },
    { manual: true, ...options },
  );
};
