import React from "react";

import type { ToastOptions } from "react-toastify";
import { toast } from "react-toastify";

import { SEMBLY_TOAST_CLASS } from "@/src/components/sembly-ui/components/SemblyToast/SemblyToastContainer";
import { ErrorIcon, InfoOutlineIcon, SuccessIcon } from "@/src/components/sembly-ui/core/BaseComponent/Icon";
import { cn } from "@/src/utils/cn";

interface SemblyToastConfig extends ToastOptions {
  withIcon?: boolean;
  icon?: React.ReactElement;
  //  Note Clickable will close any dialog
  //  TODO @agal to help fix this
  //  This is related to react-toastify vs radix dialog
  clickable?: boolean;
  buttonConfig?: {
    text: string;
    onClick: () => void;
  };
}

function BaseToastContainer(
  props: {
    showIcon: boolean;
    icon?: React.ReactElement;
    content?: string | React.ReactElement;
    clickable?: boolean;
  } = {
    showIcon: true,
  },
) {
  return (
    <div className="flex items-center font-roboto text-white">
      {props.showIcon && <div className="mr-8 h-18 w-18">{props.icon}</div>}
      <div className="text-body-1">{props.content}</div>
      {!props.clickable && <ToastClickArea />}
    </div>
  );
}

const ToastClickArea = () => {
  return <div aria-hidden="true" className={`absolute left-0 top-0 h-full w-full ${SEMBLY_TOAST_CLASS}`} />;
};

export function base(baseContent: string | React.ReactElement, baseConfig?: SemblyToastConfig) {
  toast(baseContent, baseConfig);
}

export function info(content: string | React.ReactElement, config?: SemblyToastConfig) {
  const {
    icon: customIcon = <InfoOutlineIcon className="mr-8 h-18 w-18 text-white" />,
    className: customClassName,
    withIcon = true,
    buttonConfig,
    ...restConfig
  } = config ?? {};

  const toastContent = buttonConfig ? (
    <ContentWithButton buttonConfig={buttonConfig}>{content}</ContentWithButton>
  ) : (
    content
  );

  const infoClassName = cn("!bg-sembly-gray", SEMBLY_TOAST_CLASS, customClassName);

  toast(
    <BaseToastContainer
      icon={customIcon}
      content={toastContent}
      showIcon={withIcon}
      clickable={Boolean(buttonConfig) || config?.clickable}
    />,
    {
      className: infoClassName,
      closeButton: false,
      ...(restConfig ? restConfig : {}),
    },
  );
}

export function success(content: string | React.ReactElement, config?: SemblyToastConfig) {
  const {
    icon: customIcon = <SuccessIcon className="mr-8 h-18 w-18 text-white" />,
    className: customClassName,
    withIcon = true,
    buttonConfig,
    ...restConfig
  } = config ?? {};

  const successClassName = cn("!bg-indigo-100", SEMBLY_TOAST_CLASS, customClassName);
  const toastContent = buttonConfig ? (
    <ContentWithButton buttonConfig={buttonConfig}>{content}</ContentWithButton>
  ) : (
    content
  );

  toast(
    <BaseToastContainer
      icon={customIcon}
      content={toastContent}
      showIcon={withIcon}
      clickable={Boolean(buttonConfig) || config?.clickable}
    />,
    {
      className: successClassName,
      closeButton: false,
      ...(restConfig ? restConfig : {}),
    },
  );
}

export function error(content: string | React.ReactElement, config?: SemblyToastConfig) {
  const {
    icon: customIcon = <ErrorIcon className="mr-8 h-18 w-18 text-white" />,
    className: customClassName,
    withIcon = true,
    buttonConfig,

    ...restConfig
  } = config ?? {};

  const toastContent = buttonConfig ? (
    <ContentWithButton buttonConfig={buttonConfig}>{content}</ContentWithButton>
  ) : (
    content
  );

  const errorClassName = cn("!bg-bright-red-100", SEMBLY_TOAST_CLASS, customClassName);

  toast(
    <BaseToastContainer
      icon={customIcon}
      content={toastContent}
      showIcon={withIcon}
      clickable={Boolean(buttonConfig) || config?.clickable}
    />,
    {
      className: errorClassName,
      closeButton: false,
      ...(restConfig ? restConfig : {}),
    },
  );
}

function ContentWithButton(props: {
  children: React.ReactNode;
  buttonConfig: {
    text: string;
    onClick: () => void;
  };
}) {
  return (
    <div className="relative flex items-center gap-8">
      <div>{props.children}</div>
      <div className="text-subhead-2 hover:underline" role="button" onClick={props.buttonConfig.onClick}>
        {props.buttonConfig.text}
      </div>
    </div>
  );
}

export default {
  base,
  info,
  success,
  error,
};
