import qs from "qs";

export function appendQueryString(searchParam: string, newQueryParamObj: Record<string, string | number>) {
  const params = qs.parse(searchParam, { ignoreQueryPrefix: true });
  const newParams = {
    ...params,
    ...newQueryParamObj,
  };

  return `?${qs.stringify(newParams)}`;
}

export function removeQueryParams(url: string, params: string | string[]): string {
  try {
    const urlObj = new URL(url);
    const paramsArray = Array.isArray(params) ? params : [params];

    paramsArray.forEach(param => urlObj.searchParams.delete(param));

    return urlObj.toString();
  } catch (error) {
    console.error("Invalid URL:", error);
    return url;
  }
}

export function parseQueryStringToObject(searchParam: string) {
  return qs.parse(searchParam, { ignoreQueryPrefix: true });
}
